import { useState, useEffect } from 'react'

export default function NotificationSettingsPicker({ disabledNotifications = [], setDisabled = function () {} }) {
	
	const [email, setEmail] = useState(true)
	const [sms, setSms] = useState(true)

	useEffect(() => {
		setEmail(!disabledNotifications.some(notification => notification === 'EMAIL'))
		setSms(!disabledNotifications.some(notification => notification === 'SMS'))
	}, [disabledNotifications])

	useEffect(() => {
		const newNotifications = []
		if (!email) {
			newNotifications.push('EMAIL')
		}
		if (!sms) {
			newNotifications.push('SMS')
		}

		setDisabled(newNotifications)
	}, [email, sms])

	return(
		<div>
			<label className="label">Notifications Settings</label>
			<label className="checkbox">
				<input
					onChange={(e) => setEmail(e.target?.checked)}
					checked={email}
				 	type="checkbox" 
				 	name="notificationEmail"
				 	value="EMAIL"
				/> Email
			</label>
			<label className="checkbox ml-3">
				<input 
					onChange={(e) => setSms(e.target?.checked)}
					checked={sms}
					type="checkbox" 
					name="notificationSms"
					value="SMS"
				/> SMS
			</label>
		</div>
	)
}