import { Link } from 'react-router-dom'

export default function Navigation({ activeTab }) {
	return(
		<div className="tabs is-toggle is-fullwidth">
		  <ul>
		    <li 
		    	className={activeTab === 'driver-leaderboard' ? 'is-active' : ''}
		    >
		    	<Link to="/driver/leaderboard">Leaderboard</Link>
		    </li>
		    <li 
		    	className={activeTab === 'driver-order-history' ? 'is-active' : ''}
		    >
		    	<Link to="/driver/order-history">Order History</Link>
		    </li>
		    <li 
		    	className={activeTab === 'driver-settings' ? 'is-active' : ''}
		    >
		    	<Link to="/driver/settings">Settings</Link>
		    </li>
		  </ul>
		</div>
	)
}