import DriverNavigation from './Navigation'
import { Link, useNavigate } from 'react-router-dom'
import { useSWRConfig } from 'swr'
import  { useCurrentUser } from '../../swr/index'
import { useState, useEffect } from 'react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { API_URL, POST_FETCH_OPTIONS } from '../../common/constants'
import { logout } from '../../common/helpers'

import NotificationSettingsPicker from '../../components/UI/NotificationSettingsPicker'
import OnwayFooter from '../../components/UI/OnwayFooter'

export default function Settings(){

	const { mutate } = useSWRConfig();
	const { data, isLoading, isError } = useCurrentUser()

	const [email, setEmail] = useState('')
	const [driverName, setDriverName] = useState('')
	const [deliveryCompany, setDeliveryCompany] = useState({})
	const [civilId, setCivilId] = useState('')
	const [phoneNumbers, setPhoneNumbers] = useState([])
	const [disabled, setDisabled] = useState([])

	const [errorMessage, setErrorMessage] = useState('')

	const navigate = useNavigate();

	useEffect(() => {
		if (data) {
			setEmail(data.email)
			setDriverName(data.driver_name || '')
			setDeliveryCompany(data.delivery_company || { name: '' })
			setCivilId(data.civil_id || '')
			setPhoneNumbers(data.phone_numbers || [])
			setDisabled(data.disabled_notifications || [])
		}
	}, [data])

	if (isLoading) return "Loading..."
	if (isError) return 'Error...'

	const handleSignUp = async (e) => {
		e.preventDefault()
		const formData = new FormData(e.target);
		const keys = [
			'email',
			// 'password',
			'phone',
		]

		let data = {}
		keys.forEach(key => data[key] = formData.get(key))
		data['phone_numbers'] = [data['phone']]
		data['disabled_notifications'] = disabled

		if (data.email === '') {
			toast.error('Email can\'t be empty.')
			return
		}

		try {
			const result = await fetch(API_URL.CURRENT_USER, POST_FETCH_OPTIONS(data)).then(res => res.json())
			result.success ? toast.info('Profile saved.') : toast.error(result.message)
			mutate(API_URL.CURRENT_USER)
		} catch (err) {
			toast.error('We couldn\'t update your profile. Please try again.')
		}
	}

	const changePassword = () => {
		fetch(API_URL.RESET_PASSWORD, POST_FETCH_OPTIONS({ email: data.email, isChange: true }))
			.then(res => res.json())
			.then(data => {
				data.success ? navigate(`/reset?token=${data.token}`) : setErrorMessage(data.message || 'Something went wrong. Please try again.')
			})
			.catch(err => {
				console.log(err)
			})	
	}

	return(
		<div>
			<div className="container">
				<ToastContainer />
				<h1 className="is-size-1 has-text-centered my-5">Settings</h1>
				<DriverNavigation activeTab="driver-settings" />
				<div className="max-width-md mx-auto">
					 <form onSubmit={handleSignUp}>
					 	<label className="label">Email</label>
					 	<input name="email" value={email} onChange={(e) => setEmail(e.target.value)} className="input mb-5" type="email" />

					 	{/* Add password change / reset */}
					 	<label className="label">Password</label>
					 	<input name="password" placeholder="••••••••" className="input" type="password" disabled />
					 	<button className="mt-2" onClick={() => changePassword()}>Change password</button>

					 	<hr />

					 	<label className="label">Phone number</label>
					 	<input name="phone" onChange={(e) => setPhoneNumbers(e.target.value)} value={phoneNumbers} className="input" type="text" />

					 	<hr />

					 	<label className="label">Driver name</label>
					 	<input name="driver_name" value={driverName} className="input mb-5" type="text" disabled />

						<label className="label">Delivery company</label>
						<input value={deliveryCompany.name || ''} name="delivery_company" className="input mb-4" type="text" disabled />
					 
						<label className="label">Civil ID number</label>
						<input value={civilId} name="civil_id" className="input mb-4" type="text" disabled />

						<NotificationSettingsPicker disabledNotifications={disabled} setDisabled={setDisabled} />

					 	<input className="my-4 button has-background-grey-light is-fullwidth" type="submit" value="Save" />
					 </form>
					 <div className="has-text-right mb-4">
						 <Link onClick={() => logout()} to="/">Logout</Link>
					 </div>
				</div>
			</div>
		</div>
	)
}
