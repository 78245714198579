import { useState, useEffect } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { API_URL, POST_FETCH_OPTIONS, USER_ROLE, USER_ROLE_TITLE } from '../../common/constants'

export default function SignIn() {

	const navigate = useNavigate();
	const { role } = useParams();

	useEffect(() => {
		if (role === 'driver' || role === 'business') {
		} else {
			navigate('/')
		}
	}, [])

	const [errorMessage, setErrorMessage] = useState('')

	const handleSignUp = (e) => {
		e.preventDefault()
		const formData = new FormData(e.target);
		const keys = [
			'email', 
			'password',
		]

		let data = {}
		keys.forEach(key => data[key] = formData.get(key))
		data.role = role === 'driver' ? USER_ROLE.DRIVER : USER_ROLE.BUSINESS
		if (validateForm(data)) {
			fetch(API_URL.LOGIN, POST_FETCH_OPTIONS(data))
				.then(res => res.json())
				.then(data => {
					if (!data.id) { 
						setErrorMessage(data.message)
						return
					}
					localStorage.setItem('token', data.token);
					localStorage.setItem('userId', data.id);
					localStorage.setItem('role', data.role);
					data.role === USER_ROLE.BUSINESS ? navigate('/dashboard') : navigate('/driver/leaderboard')
				})
				.catch(err => {
					console.log(err)
				})	
		} else {
			setErrorMessage('Please enter a valid email / password.')
		}
	}

	const validateForm = (data) => {
		if (data.email && data.password) {
			return true
		}
		return false
	}

	return(
		<div className="center">
			<div className="max-width-md mx-auto">
				<h1 className="is-size-1 has-text-centered my-4">{USER_ROLE_TITLE[role.toUpperCase()]} Sign in</h1>
				<form onSubmit={handleSignUp}>
					<label className="label">Email</label>
					<input name="email" className="input mb-4" type="email" />

					<label className="label">Password</label>
					<input name="password" className="input mb-4" type="password" />

					{errorMessage && <p className="has-text-danger">{errorMessage}</p>}
					<input className="has-background-grey-light button is-fullwidth mt-2" type="submit" value="Sign in" />
				</form>

				<div className="my-3 has-text-centered">
					<Link to="/forgot-password">Forgot password?</Link>
				</div>
			</div>
		</div>
	)
}