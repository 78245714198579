import React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

//** AUTH *****
import LandingPage from './pages/pre-login/LandingPage'
import DriverLandingPage from './pages/pre-login/DriverLanding'
import BusinessLandingPage from './pages/pre-login/BusinessLanding'

import SignUpPage from './pages/pre-login/SignUpPage'
import SignInPage from './pages/pre-login/SignInPage'
import ForgotPasswordPage from './pages/pre-login/ForgotPasswordPage'
import ResetPage from './pages/pre-login/ResetPage'
import ActivationPage from './pages/pre-login/ActivationPage'

//** Business Facing *******
import CheckBusinessAuth from './pages/CheckBusinessAuth'
import DashboardPage from './pages/Dashboard'
import OrderDetailPage from './pages/Dashboard/OrderDetailPage'
import AddProductsPage from './pages/AddProductsPage'
import PlaceOrderPage from './pages/PlaceOrderPage'
import SettingsPage from './pages/SettingsPage'

//** Driver Facing ***********
import CheckDriverAuth from './pages/driver/CheckAuth'
import LeaderboardPage from './pages/driver/LeaderboardPage'
import OrderDetails from './pages/driver/OrderDetails'
import OrderHistory from './pages/driver/OrderHistory'
import DriverSettings from './pages/driver/Settings'

//** Admin Facing *********
import AdminLandingPage from './pages/admin/LandingPage'
import AdminCheckAuth from './pages/admin/CheckAuth'

import AdminDashboardPage from './pages/admin/Dashboard'
import AdminBusinessesPage from './pages/admin/Businesses'
import AdminTrackingPage from './pages/admin/Tracking'
import AdminFeesPage from './pages/admin/Fees'
import AdminSettingsPage from './pages/admin/Settings'

import ExperimentalPage from './pages/Experimental'

const App = () => (
  <BrowserRouter>

    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/driver" element={<DriverLandingPage />} />
      <Route path="/business" element={<BusinessLandingPage />} />

      <Route path="/:role/sign-up" element={<SignUpPage />} />
      <Route path="/:role/sign-in" element={<SignInPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />

      <Route path="/reset" element={<ResetPage />} />
      <Route path="/activate" element={<ActivationPage />} />

      <Route element={<CheckBusinessAuth />}>
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/add-products" element={<AddProductsPage />} />
        <Route path="/place-order" element={<PlaceOrderPage />} />
        <Route path="/settings" element={<SettingsPage />} />
      </Route>

      <Route path="/order/:orderId" element={<OrderDetailPage />} />

      <Route element={<CheckDriverAuth />}>
        <Route path="/driver/leaderboard" element={<LeaderboardPage />} />
        <Route path="/driver/leaderboard/:orderId" element={<OrderDetails />} />
        <Route path="/driver/order-history" element={<OrderHistory />} />
        <Route path="/driver/settings" element={<DriverSettings />} />
      </Route>

      <Route path="/admin" element={<AdminLandingPage />} />
      <Route element={<AdminCheckAuth />}>
        <Route path="/admin/dashboard" element={<AdminDashboardPage />} />
        <Route path="/admin/businesses" element={<AdminBusinessesPage />} />
        <Route path="/admin/tracking" element={<AdminTrackingPage />} />
        <Route path="/admin/fees" element={<AdminFeesPage />} />
        <Route path="/admin/settings" element={<AdminSettingsPage />} />

      </Route>

      <Route path="/experimental" element={<ExperimentalPage />} />
    </Routes>
  </BrowserRouter>
);

export default App;